import store from "../Store/store";

export const END_POINT = "https://email-template-cpdz.onrender.com";

export const API_URL_CONSTANT = {
  SIGN_IN: `${END_POINT}token`,
  SIGN_UP: `${END_POINT}normal_user`,
  GOOGLE_AUTH: `${END_POINT}social-login`,
  SEND_MAIL: `${END_POINT}/send_email_without_attachments/`,
  SEND_MAIL_ATTACH: `${END_POINT}/send_email_with_attachments/`,
};

export const ROUTER_URL_CONSTANT = {
  MAIN: "/",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
};

export const dispatchStore = store.dispatch as
  | typeof store.dispatch
  | React.Dispatch<any>;

export const headers = {
  Authorization: "Basic Z3VuYXNla2hhcjEyMzpndW5hc2VraGFyMTIz",
  accept: "application/json",
};
