import React from 'react'
import Email from '../Email/Email'

const Home = () => {
  return (
    <div>
      <Email />
    </div>
  )
}

export default Home