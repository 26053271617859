import axios from "axios";
import { toast } from "react-toastify";
import { authActionTypes } from "../Store/Auth";
import { API_URL_CONSTANT } from "../Utilities/constants";

export function sendEmailAttachAPICall(
  to_mail: string,
  subject: string,
  message: string,
  formData: FormData
) {
  return (dispatch: any) => {
    dispatch({
      type: authActionTypes.SEND_MAIL,
      payload: {
        data: [],
        loader: true,
        error: null,
      },
    });

    // Construct the URL with query parameters
    const url = `${API_URL_CONSTANT.SEND_MAIL_ATTACH}?to_mail=${encodeURIComponent(
      to_mail
    )}&subject=${encodeURIComponent(subject)}&message=${encodeURIComponent(message)}`;

    axios
      .post(url, formData, {
        headers: {
          Authorization: "Basic Z3VuYXNla2hhcjEyMzpndW5hc2VraGFyMTIz",
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        if (response.data) {
          dispatch({
            type: authActionTypes.SEND_MAIL,
            payload: {
              data: response.data,
              loader: false,
              error: null,
            },
          });
          toast.success("Email sent successfully!");
        }
      })
      .catch((error: any) => {
        dispatch({
          type: authActionTypes.SEND_MAIL,
          payload: {
            data: [],
            loader: false,
            error: error.response ? error.response.data : "Network Error",
          },
        });

        if (error?.response?.status === 400) {
          toast.error(
            error?.response?.data?.detail ||
              "Failed to send email. Check your input."
          );
        } else {
          toast.error("Something went wrong. Please try again!");
        }
      });
  };
}
