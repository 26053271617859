import React from "react";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import "./Email.css";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { getSendMailState } from "../../Store/Auth/selector";
import { authSelectors } from "../../Store/Auth";
import { dispatchStore } from "../../Utilities/constants";
import { sendEmailAPICall } from "../../Services/SendEmail";
import { sendEmailAttachAPICall } from "../../Services/SendEmailAttach";

type FormData = {
  to_email: string;
  subject: string;
  message: string;
  files: File[];
};

const Email = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormData>();

  const files = watch("files", []);

  const Fetch_Send_Mail = useSelector(authSelectors.getSendMailState);
  const loadingState = Fetch_Send_Mail?.loader;
  // Handle form submission
  const onSubmit = (data: FormData) => {
    console.log("Form Data:", data);
    if (data.files) {
      const formData = new FormData();
      data.files.forEach((file: any) => {
        formData.append("files", file);
      });
      dispatchStore(
        sendEmailAttachAPICall(
          data.to_email,
          data.subject,
          data.message,
          formData
        )
      );
    } else {
      dispatchStore(
        sendEmailAPICall(data.to_email, data.subject, data.message)
      );
    }
  };

  // Handle file drop
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setValue("files", [...files, ...acceptedFiles]);
    },
    multiple: true,
  });

  // Function to delete a file
  const deleteFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setValue("files", updatedFiles);
  };

  return (
    <div className="email-outer-layout">
      <div className="email-inner-layout">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="to_email">Recipient Email:</label>
            <input
              type="email"
              id="to_email"
              {...register("to_email", { required: "Email is required" })}
            />
            {errors.to_email && (
              <span className="validator">{errors.to_email.message}</span>
            )}
          </div>

          <div>
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              id="subject"
              {...register("subject", { required: "Subject is required" })}
            />
            {errors.subject && (
              <span className="validator">{errors.subject.message}</span>
            )}
          </div>

          <div>
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              {...register("message", { required: "Message is required" })}
            />
            {errors.message && (
              <span className="validator">{errors.message.message}</span>
            )}
          </div>

          <div className="file-upload">
            <label htmlFor="files">Attach Files (Optional):</label>
            <div
              {...getRootProps({
                className:
                  "dropzone d-flex flex-row align-items-center justify-content-center",
              })}
            >
              <input {...getInputProps()} id="files" />
              <p>Drag & Drop files here, or click to select files</p>
            </div>
            <div>
              <ul className="d-flex flex-row flex-wrap gap-2">
                {/* Display the list of selected files with delete option */}
                {files.length > 0 &&
                  files.map((file, index) => (
                    <li key={index}>
                      {file.name}{" "}
                      <MdDelete
                        onClick={() => deleteFile(index)}
                        style={{ cursor: "pointer" }}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <button type="submit" className="but">
            {loadingState ? "Sending.." : "Send Email"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Email;
