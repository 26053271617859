import { SystemState } from "../storeTypes";

export const getLoginState = (state: SystemState) => state.auth.signin;

export const getSignUpState = (state: SystemState) => state.auth.signup;

export const getGoogleAuthState = (state: SystemState) => state.auth.googleauth;

export const getSendMailState = (state: SystemState) => state.auth.send_mail;

export const getSendMailAttachState = (state: SystemState) => state.auth.send_mail_attach;
