import {
  SIGN_IN,
  SIGN_UP,
  GOOGLE_AUTH,
  SEND_MAIL,
  SEND_MAIL_ATTACH,
} from "./actionTypes";
import { authActionTypes } from "./types";

export const initialLoginState = {
  signin: {
    data: [],
    loader: false,
    error: null,
  },
  signup: {
    data: [],
    loader: false,
    error: null,
  },
  googleauth: {
    data: [],
    loader: false,
    error: null,
  },
  send_mail: {
    data: [],
    loader: false,
    error: null,
  },
  send_mail_attach: {
    data: [],
    loader: false,
    error: null,
  },
};

export default (state = initialLoginState, action: authActionTypes) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        signin: {
          ...state.signin,
          data: action.payload.data,
          loader: action.payload.loader,
          error: action.payload.error,
        },
      };
    case SIGN_UP:
      return {
        ...state,
        signup: {
          ...state.signup,
          data: action.payload.data,
          loader: action.payload.loader,
          error: action.payload.error,
        },
      };
    case GOOGLE_AUTH:
      return {
        ...state,
        googleauth: {
          ...state.googleauth,
          data: action.payload.data,
          loader: action.payload.loader,
          error: action.payload.error,
        },
      };
    case SEND_MAIL:
      return {
        ...state,
        send_mail: {
          ...state.send_mail,
          data: action.payload.data,
          loader: action.payload.loader,
          error: action.payload.error,
        },
      };
    case SEND_MAIL_ATTACH:
      return {
        ...state,
        send_mail_attach: {
          ...state.send_mail_attach,
          data: action.payload.data,
          loader: action.payload.loader,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};
