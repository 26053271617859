import axios from "axios";
import { toast } from "react-toastify";
import { authActionTypes } from "../Store/Auth";
import { API_URL_CONSTANT } from "../Utilities/constants";

export function sendEmailAPICall(to_mail: string, subject: string, message: string) {
  return (dispatch: any) => {
    dispatch({
      type: authActionTypes.SEND_MAIL,
      payload: {
        data: [],
        loader: true,
        error: null,
      },
    });

    // Encode query parameters to handle special characters
    const url = `${API_URL_CONSTANT.SEND_MAIL}?to_mail=${encodeURIComponent(to_mail)}&subject=${encodeURIComponent(subject)}&message=${encodeURIComponent(message)}`;

    axios
      .post(
        url,
        {},
        {
          headers: {
            "Authorization": "Basic Z3VuYXNla2hhcjEyMzpndW5hc2VraGFyMTIz",
            "accept": "application/json",
          },
        }
      )
      .then((response: any) => {
        if (response.data) {
          dispatch({
            type: authActionTypes.SEND_MAIL,
            payload: {
              data: response.data,
              loader: false,
              error: null,
            },
          });
          toast.success("Email sent successfully!");
          window.location.reload()
        }
      })
      .catch((error: any) => {
        dispatch({
          type: authActionTypes.SEND_MAIL,
          payload: {
            data: [],
            loader: false,
            error: error.response ? error.response.data : "Network Error",
          },
        });

        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.detail || "Failed to send email. Check your input.");
        } else {
          toast.error("Something went wrong. Please try again!");
        }
      });
  };
}
